import * as React from 'react';
import { DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 2;
`;

const Root = styled('div')`
  position: relative;
`;

export const DialogRoot =  ({ handleClose, children }) => {
  return <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CloseBtnRoot>
        <DialogContent sx={{ p: 0 }}>
          {children}
        </DialogContent>
  </Root>
}