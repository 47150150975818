import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, LinearProgress, Tab, Tabs } from '@mui/material';
import ToolBarImageSwap from './ToolBarImageSwap';
import PaginationTable from './Pagination';
import { apiArticleProvider, EngageState } from '../store/imageSwapSlice';
import { useContext, useEffect } from 'react';
import { VideoDetailedWithMF } from './modal/VideoDetailedWithFM';
import { ImageSwapArticleTable } from './ImageSwapArticleTable';
import { KeywordHeading } from './common/Heading';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { OrganisationContext } from '../App';
import { RootState } from '../index';
import { FMArticle } from './types/matches';

export default function ImageSwap({ type, title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOrganisation, organisations } = useContext(OrganisationContext);

  const {
    articlesFilter,
    isGroupByKeyword,
    articles,
    articleGroupByKeyword,
    pagination,
    filteredTopic,
    busy,
    formatFilter,
  } = useSelector<RootState, EngageState>((state) => state.imageSwap);


  const [articleVideoPreview, setArticleVideoPreview] = React.useState<FMArticle | undefined>();
  const handleClose = () => setArticleVideoPreview(undefined);
  const [orderDirection, setOrderDirection] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('traffic');

  const sorting = type => () => {
    if (orderBy !== type) {
      setOrderDirection('desc');
    }
    if (orderBy === type) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(type);
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(
      apiArticleProvider({
        publisher: selectedOrganisation,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByDate: articlesFilter,
        orderBy: orderBy,
        orderDirection: orderDirection,
        isGroupByKeyword,
        keyword_id: filteredTopic.join(','),
        format: formatFilter,
        type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOrganisation,
    pagination.itemsPerPage,
    pagination.currentPage,
    articlesFilter,
    orderDirection,
    orderBy,
    isGroupByKeyword,
    formatFilter,
  ]);

  const currentOrg = organisations.find(({ domain }) => domain === selectedOrganisation);

  return articles ? (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoDetailedWithMF
          open={Boolean(articleVideoPreview)}
          handleClose={handleClose}
          video={{
            media_id: articleVideoPreview.video_media_id,
            rightsholder: articleVideoPreview.video_rightsholder,
            video_type: articleVideoPreview.video_type,
            format: articleVideoPreview.video_format,
            title: articleVideoPreview.video_title,
            publish_date: new Date(articleVideoPreview.video_published_date),
            parent_media_id: articleVideoPreview.video_parent_media_id,
            has_variant: false,
            can_manage_video: false,
            summary: articleVideoPreview.video_summary,
            article_thumbnail: articleVideoPreview.thumbnail,
            article_title: articleVideoPreview.article_title,
            matched_score: articleVideoPreview.matched_score_percentage,
          }}
        />
      )}

      <ToolBarImageSwap title={title} />
      {!isGroupByKeyword && articles && (
        <>
          <Tabs
            value={type}
            onChange={(e, value) => {
              setOrderBy(value === 'no-matches' ? 'traffic' : 'score');
              navigate(`/dashboard/video-swap${value === 'review' ? '' : `/` + value}`);
            }}
            aria-label="basic tabs example"
            sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <Tab label="Image articles" value={'review'} />
            <Tab label="Video articles" value={'improve'} />
            {!currentOrg?.autopublish && <Tab label="Swapped" value={'swapped'} />}
            <Tab label="Needs better video" value={'no-matches'} />
          </Tabs>
          <Box sx={{ position: 'relative' }}>
            {busy && articleGroupByKeyword && (
              <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />
            )}
            {/*{busy && articleGroupByKeyword && <CircularProgress size={20} sx={{ 'position': 'absolute', 'top': -28, 'right': 12 }} color="primary" />}*/}
            <ImageSwapArticleTable
              articles={articles}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onSortByTraffic={sorting('traffic')}
              onSortByDate={sorting('date')}
              onSortByScore={sorting('score')}
              onSetArticleForPreview={setArticleVideoPreview}
              articleDemo={false}
              selectedPublisher={selectedOrganisation}
              format={formatFilter}
            />
          </Box>
        </>
      )}
      {isGroupByKeyword &&
        Object.entries<Record<string, any>>(articleGroupByKeyword).map(([keyword, items]) => (
          <div>
            <KeywordHeading>{keyword}</KeywordHeading>
            <ImageSwapArticleTable
              articles={items.image_articles}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onSortByTraffic={sorting('traffic')}
              onSortByDate={sorting('date')}
              onSortByScore={sorting('score')}
              onSetArticleForPreview={setArticleVideoPreview}
              articleDemo={false}
              selectedPublisher={selectedOrganisation}
            />
          </div>
        ))}
      {busy && articleGroupByKeyword && <LinearProgress color="success" />}
      <PaginationTable
        defaultSize={20}
        busy={busy}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </React.Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
