import { Dialog, DialogContent, IconButton, Tab } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { TabList } from '@mui/lab';
import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { VideoProps } from '../types/videos';
import { PanelVideo } from './video/PanelVideo';
import { PanelVideoSummary } from './video/PanelVideoSummary';
import Box from '@mui/material/Box';

const CloseBtnRoot = styled('div')`
    position: absolute;
    right: 8px;
    top: 5px;
    z-index: 2;
`;

const Root = styled('div')`
    position: relative;
`;

export const VideoBasicPreview = ({
  open,
  handleClose,
  video,
}: {
  open: boolean,
  handleClose: () => void,
  video: VideoProps;
}) => {
  const [tabValue, setTabValue] = useState('1');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </CloseBtnRoot>
        <DialogContent sx={{ p: 0 }}>
          <TabContext value={tabValue}>
            <TabList onChange={(_, newValue) => setTabValue(newValue)}
                     sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <Tab value="1" label="Video"/>
              <Tab value="2" label="Summary"/>
              {video.parent_media_id && <Tab value="3" label="Original"/>}
            </TabList>
          </TabContext>
          <Box sx={{
            minHeight: '660px'
          }}>
            {tabValue === '1' && <PanelVideo video={video}/>}
            {tabValue === '2' && <PanelVideoSummary summary={video.summary}/>}
            {tabValue === '3' && <PanelVideo video={{
              ...video,
              format: 'horizontal',
              media_id: video.parent_media_id as string
            }}/>}
          </Box>

        </DialogContent>
      </Root>
    </Dialog>
  );
};
