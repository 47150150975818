import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import { OrganisationContext } from '../../App';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { DialogRoot } from './DialogRoot';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Download, UploadFile } from '@mui/icons-material';
import styled from '@emotion/styled';

const DownloadArea = styled(Box)`
    display: flex;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    position: relative;
    &[drop-active]:before {
        border-width: 2px;
    }
    &:before {
        content: ' ';
        border: 1px dashed cornflowerblue;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
`

export const EditVideoRequest = ({ open, handleClose, video }) => {
  const [message, setMessage] = useState<string>();
  const [postSubmit, setPostSubmit] = useState<boolean>();
  const [uploadSubmitFeedback, setUploadSubmitFeedback] = useState<string>();
  const [tabValue, setTabValue] = useState<string>('2');
  const [file, setCurrentFile] = useState<File>();
  const dropArea = useRef<HTMLDivElement>();
  const inputFile = useRef<HTMLInputElement>(null);

  const { selectedOrganisation } = useContext(OrganisationContext);
  const onSave = async () => {
    await axios.post(`${getApiBaseUrl()}/video-clip/${selectedOrganisation}/edit-video-request`, {
      media_id: video.media_id,
      instruction: message,
      user_email: localStorage.getItem('user_email')
    }).catch(() => setPostSubmit(false));
    setPostSubmit(true);
  }

  const upload = async () => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    formData.append('media_id', video.media_id);

    await axios.post(`${getApiBaseUrl()}/video-clip/${video.rightsholder}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        setUploadSubmitFeedback(undefined);
        setPostSubmit(true);
      })
      .catch((message) => {
      if (typeof message === 'string'){
        setUploadSubmitFeedback(message);
      } else {
        setUploadSubmitFeedback('Sorry, we are unable to process this request.')
      }
    });

  }

  const dropFileCallback = (files: FileList | null) => {
    if (files && files.length > 0) {
      const [video] = files;
      if (!/mp4$/.test(video.type)) {
        alert('Unsupported file type, please upload mp4 video');
        return;
      }
      setCurrentFile(video)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogRoot handleClose={handleClose}>
        <TabContext value={tabValue}>
            <TabList onChange={(_, newValue) => setTabValue(newValue)}
                     sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <Tab value="1" label="Edit request"/>
              <Tab value="2" label="Offline edit"/>
            </TabList>
          </TabContext>
          <Box sx={{ pl: 4, pr: 4, pb: 4 }}>
            {tabValue === '1' &&
              <Grid container spacing={2} style={{ marginTop: 0 }}>
                { postSubmit !== undefined && <Grid item xs={12}>
                    { postSubmit && <Typography color={'green'} fontSize={'12px'}>Instruction successfully submitted</Typography> }
                    { !postSubmit && <Typography color={'red'} fontSize={'12px'}>Sorry, this instruction has failed.</Typography> }
                </Grid>}
                <Grid item xs={12}>
                  <Typography fontSize={'12px'}>Media id: {video.media_id}</Typography>
                  <Typography fontSize={'12px'}>Title: {video.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={5}
                      id="instruction"
                      label="Message / instruction"
                      variant="outlined"
                      type={'text'}
                      onChange={({ target: { value }}) => setMessage(value)}
                      value={message}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={'flex-end'}>
                    <Button color="primary" variant="contained" onClick={() => onSave()}>
                      Send request
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
            {tabValue === '2' && <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={12}>
                <Typography sx={{ mb: 2 }}>Download premier xml feature. Coming soon.</Typography>
                <Button variant={'contained'} disabled startIcon={<Download />}>Download</Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {uploadSubmitFeedback && <Typography fontSize={'13px'} color={'darkred'}>{uploadSubmitFeedback}</Typography>}
                <DownloadArea
                  ref={dropArea}
                  draggable
                  onDrop={(e) => {
                    e.preventDefault();
                    dropFileCallback(e.dataTransfer.files);
                    dropArea?.current?.removeAttribute('drop-active');
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    dropArea?.current?.setAttribute('drop-active', '');
                  }}
                  onDragLeave={() => dropArea?.current?.removeAttribute('drop-active')}
                >
                  { !file && <Typography sx={{ m: 4, textAlign: 'center' }}>Drag and drop video you want to upload here, or choose 'Add video'.</Typography>}
                  { file && <Typography sx={{ m: 4, textAlign: 'center', wordBreak: 'break-word' }}>{file.name}</Typography>}
                </DownloadArea>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Button variant={'outlined'} startIcon={<UploadFile/>} onClick={() => inputFile.current?.click()}>
                    Add video
                    <input
                      ref={inputFile}
                      type="file"
                      onChange={(e) => dropFileCallback(e.target.files)}
                      accept="video/mp4"
                      hidden
                    />
                  </Button>
                  <Button disabled={!file} onClick={upload} variant={'contained'} startIcon={<UploadFile />}>Upload</Button>
                </Grid>
              </Grid>
            </Grid>}
          </Box>
      </DialogRoot>
    </Dialog>
  )
};
