import { styled } from '@mui/material/styles';
import _TableRow from '@mui/material/TableRow';
import _TableCell from '@mui/material/TableCell';

export const TableRow = styled(_TableRow)`
  background-color: rgba(51, 85, 209, 0.03);
  border-bottom: 1px solid #FBFBFB;
  font-weight: bold;
`;

export const TableCellHead = styled(_TableCell)`
 font-family: ProximaNovaBold;
 font-size: 12px;
 text-transform: uppercase;
    white-space: nowrap;
`;

export const TableCellBody = styled(_TableCell)`
  
`;

export const TableCellBodyLight = styled(_TableCell)`
  font-family: ProximaNovaLight;
  font-size: 14px
`;
