import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import {
  IABCategory, Player,
  PlaylistAPIData, PlaylistCampaignDeliveryArticle,
  PlaylistPublisher,
  PlaylistTrendingItem
} from '../components/types/playlist';

const fetchPlaylistSuccess = payload => ({
  type: 'FETCH_PLAYLIST_SUCCESS',
  payload,
});

const fetchFormOptionsSuccess = payload => ({
  type: 'FETCH_FORM_OPTIONS_SUCCESS',
  payload,
});

const fetchPlaylistsSuccess = payload => ({
  type: 'FETCH_PLAYLISTS_SUCCESS',
  payload,
});

const fetchPlaylistsTrendingArticleSuccess = payload => ({
  type: 'FETCH_PLAYLISTS_TRENDING_ARTICLE_SUCCESS',
  payload,
});

const fetchPlaylistsCampaignDeliverySuccess = payload => ({
  type: 'FETCH_PLAYLISTS_CAMPAIGN_DELIVERY_SUCCESS',
  payload,
});

const fetchPlayerSuccess = payload => ({
  type: 'FETCH_PLAYER_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_PLAYLIST_BUSY',
  payload,
});

type Playlist = {
  name: string
}

export const apiPlaylistProvider =
  ({ publisher, selectedPlaylist, formatFilter, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/${publisher}/${selectedPlaylist}`, {
        params,
      });
      dispatch(fetchPlaylistSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiFormOptionsProvider =
  () =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/form-options`);
      dispatch(fetchFormOptionsSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiGetPlaylists =
  ({ publisher }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/${publisher}`);
      dispatch(fetchPlaylistsSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiGetPlaylistTrendingArticle =
  ({ publisher, playlistId }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/popular-article/${publisher}/${playlistId}`);
      dispatch(fetchPlaylistsTrendingArticleSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiGetPlaylistCampaignDelivery =
  ({ publisher, playlistId, dateRange, page, limit }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/campaign-delivery/${publisher}/${playlistId}`, {
        params: {
          dateRange, page, limit
        }
      });
      dispatch(fetchPlaylistsCampaignDeliverySuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiGetPlayer =
  ({ publisher, playlistId }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/playlist/${publisher}/${playlistId}/players`);
      dispatch(fetchPlayerSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface PlaylistState {
  trendingArticles: PlaylistTrendingItem[];
  videos: any;
  playlists: Playlist[];
  campaignDelivery: PlaylistCampaignDeliveryArticle[];
  playlistTypes: {id: number, playlistTypes: string}[];
  formatFilter: string;
  paginationPage: number;
  busy: boolean;
  paginationPerPage: number;
  currentPlaylist: PlaylistAPIData;
  iabCategories: IABCategory[] | null;
  playlistPublishers: PlaylistPublisher[] | null;
  geoCountries: string[];
  players: Player[]
  pagination: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

const initialState: PlaylistState = {
  trendingArticles: [],
  playlistTypes: [],
  playlists: [],
  videos: [],
  players: [],
  campaignDelivery: [],
  paginationPage: 0,
  formatFilter: 'vertical',
  paginationPerPage: 20,
  busy: false,
  currentPlaylist: null as unknown as PlaylistAPIData,
  iabCategories: [],
  playlistPublishers: [],
  geoCountries: [],
  pagination: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
  },
};

const reducer = (state = initialState, action): PlaylistState => {
  switch (action.type) {
    case 'SET_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    case 'FETCH_PLAYLIST_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    case 'FETCH_PLAYLISTS_TRENDING_ARTICLE_SUCCESS': {
      const { items, detail } = action.payload;
      return {
        ...state,
        currentPlaylist: detail,
        trendingArticles: items,
      };
    }
    case 'FETCH_PLAYLISTS_SUCCESS':
      return {
        ...state,
        playlists: action.payload,
      };
    case 'FETCH_FORM_OPTIONS_SUCCESS':
      const { iabCategories, publishers, types, geoCountries } = action.payload;
      return {
        ...state,
        iabCategories,
        playlistPublishers: publishers,
        playlistTypes: types,
        geoCountries
      };
    case 'FETCH_PLAYLIST_SUCCESS':
      let { items: videos, meta: pagination, detail } = action.payload;
      return {
        ...state,
        currentPlaylist: detail,
        videos,
        pagination,
      };
    case 'SET_FILTER_FORMAT':
      return {
        ...state,
        formatFilter: action.payload,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };
    case 'FETCH_PLAYLISTS_CAMPAIGN_DELIVERY_SUCCESS': {
      const { items, meta } = action.payload;
      return {
        ...state,
        campaignDelivery: items,
        pagination: meta,
      }
    }
    case 'FETCH_PLAYER_SUCCESS': {
      const {
        players,
        detail
      } = action.payload
      return {
        ...state,
        players,
        currentPlaylist: detail
      }
    }
    case 'RESET_PLAYLIST_VIDEO': {
      return {
        ...state,
        campaignDelivery: initialState.campaignDelivery,
        currentPlaylist: initialState.currentPlaylist,
        videos: initialState.videos,
        pagination: initialState.pagination,
      };
    }
    default:
      return state;
  }
};

export default reducer;
