import {
  Dialog,
  DialogTitle,
  Grid,
  Link,
  Tab,
  Typography
} from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TabList } from '@mui/lab';
import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { VideoWithFMProps } from '../types/videos';
import { PanelVideo } from './video/PanelVideo';
import { PanelVideoSummary } from './video/PanelVideoSummary';
import Box from '@mui/material/Box';
import { DialogRoot } from './DialogRoot';

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 16px;

  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 40px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

export const VideoDetailedWithMF = ({
  open,
  handleClose,
  video,
}: {
  open: boolean,
  handleClose: () => void,
  video: VideoWithFMProps;
}) => {
  const [tabValue, setTabValue] = useState('1');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <DialogRoot handleClose={handleClose}>
        <TitleDialog>
            <Grid container alignItems={'center'}>
              <Grid item xs={3}>
                <img src={`${video.article_thumbnail}?width=100`} alt={''} style={{ maxWidth: '100px' }} />
              </Grid>
              <Grid item xs={9}>
                <Typography>
                  <Link href="#" target={'_blank'}>
                    {video.article_title}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </TitleDialog>

          <TabContext value={tabValue}>
            <TabList onChange={(_, newValue) => setTabValue(newValue)}
                     sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <Tab value="1" label="Video"/>
              <Tab value="2" label="Summary"/>
              { Boolean(video.parent_media_id) && <Tab value="3" label="Original"/>}
            </TabList>
          </TabContext>
          <Box sx={{
            minHeight: '660px',
            display: 'flex'
          }}>
          {tabValue === '1' && <PanelVideo video={video}/>}
          {tabValue === '2' && <PanelVideoSummary summary={video.summary}/>}
          {tabValue === '3' && <PanelVideo video={{
              ...video,
              format: 'horizontal',
              media_id: video.parent_media_id as string
            }}/>}
          </Box>
      </DialogRoot>
    </Dialog>
  );
};
