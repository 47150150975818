import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { PublicationSelector } from './PublicationSelector';
import { useDispatch, useSelector } from 'react-redux';
import { apiReportUrlProvider } from '../store/reportSlice';
import { ButtonGroup, FormControl, Grid, LinearProgress, MenuItem, Select, Toolbar } from '@mui/material';
import { Heading } from './common/Heading';
import { OrganisationContext } from '../App';

const ReportFrame = styled('iframe')`
  width: 100%;
  height: 85vh;
  border: none;
  background-color: transparent;
`;

export default function Report() {
  const dispatch = useDispatch();
  const { selectedOrganisation, organisations } = useContext(OrganisationContext);


  const currentOrg = organisations.find(({ domain }) => domain === selectedOrganisation);


  const { url, busy, formatFilter } = useSelector((state: any) => state.report);

  useEffect(() => {
    if (!selectedOrganisation) return;
    const rightsholder = Array.isArray(currentOrg?.orgtype) && currentOrg?.orgtype[0] === 'rightsholder' ? [selectedOrganisation] : ''

    dispatch<any>(
      apiReportUrlProvider({
        publisher: selectedOrganisation,
        params: {
          // date_range: 'previous7days',
          dashboardId: 72,
          format: formatFilter,
          rightsholder
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation, formatFilter]);

  return (
    <React.Fragment>
      <Toolbar style={{ paddingBottom: '0', paddingLeft: '15px', justifyContent: 'flex-end' }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Heading>Format</Heading>
          </Grid>
          <Grid item>
            <ButtonGroup>
              <FormControl
                variant="outlined"
                margin="dense"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <Select
                  id="format-select"
                  value={formatFilter}
                  onChange={event => {
                    dispatch({ type: 'SET_FILTER_FORMAT', payload: event.target.value });
                  }}
                  sx={{
                    fontFamily: 'ProximaNovaBold',
                    textTransform: 'none',
                    color: '#000',
                    height: '43px',
                    width: '200px',
                    marginRight: '30px',
                  }}
                >
                  {['horizontal', 'vertical'].map((format: string) => (
                    <MenuItem key={format} value={format}>
                      {format}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ButtonGroup>
          </Grid>
        </Grid>
        <PublicationSelector />
      </Toolbar>
      {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
      <ReportFrame src={url}></ReportFrame>
    </React.Fragment>
  );
}
