import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import { BoldTypography } from './common/TextLabel';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import { decode } from 'he';
import { ReactComponent as CopyContentIcon } from '../icons/copy-content.svg';
import { Grid, SvgIcon } from '@mui/material';
import copyToClipboard from './utils/copyToClipboard';
import ProducerHeading from './ProducerHeading';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import { VideoLibItem } from './types/videos';

type VidLibProps = {
  videos: VideoLibItem[],
  onSetVideoPreview: (y: number) => void,
  onEditRequested: (y: VideoLibItem) => void
}

export const VideoLibraryTable = ({ videos, onSetVideoPreview, onEditRequested }: VidLibProps) => {
  const download = async  (mediaId: string, rightsholder: string) => {
    const { data } = await axios.get(`${getApiBaseUrl()}/video-clip/${rightsholder}/download-clip`, { params: {
      mediaId
      }});
    window.location.href = data;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>VIDEO</TableCellHead>
          <TableCellHead>TITLE</TableCellHead>
          <TableCellHead>DATE</TableCellHead>
          <TableCellHead>SUMMARY</TableCellHead>
          <TableCellHead>IAB CATEGORY</TableCellHead>
          <TableCellHead>DURATION</TableCellHead>
          <TableCellHead>RIGHTSHOLDER</TableCellHead>
          <TableCellHead></TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {videos.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={7} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>There are no videos yet</div>
            </TableCellBody>
          </TableRow>
        )}
        {videos &&
          videos.map((row, index) => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.media_id}
            >
              <TableCellBody
                sx={{ width: row.format === 'vertical' ? '104px' : '160px', position: 'relative' }}
                onClick={() => {
                  onSetVideoPreview(index);
                }}
              >
                {row.format === 'vertical' && (
                  <><div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '128px',
                      borderRadius: '5%',
                    }}
                  ></div>
                    { row.parent_media_id && <img src="/logo/pixels.png" style={{
                    width: '24px',
                    borderRadius: '4px',
                    position: 'absolute',
                    top: '2px',
                    right: '10px',
                  }} alt=""/> }
                    </>
                )}
                {row.format === 'horizontal' && (
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '72px',
                      borderRadius: '5%',
                    }}
                  ></div>
                )}
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '287px' }}>
                {decode(row.title)}
                <div title={row.media_id} style={{ cursor: 'pointer', marginTop: '10px' }}>
                  <SvgIcon onClick={copyToClipboard(row.media_id)}>
                    <CopyContentIcon />
                  </SvgIcon>
                </div>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMMM">{row.publish_date}</Moment>
                </BoldTypography>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>{row.summary}</BoldTypography>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  {row.iab_topics.map(({ text, score }) => (
                    <span key={text} title={score} style={{ display: 'block' }}>
                      {text} ({(score * 100).toFixed(0)}%)
                    </span>
                  ))}
                </BoldTypography>
              </TableCellBody>
              <TableCellBody>{row.duration}</TableCellBody>
              <TableCellBody sx={{ minWidth: '150px' }}>
                <ProducerHeading row={row} />
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '150px' }}>
                { row.parent_media_id && row.canManageVideo && <Grid container spacing={1}>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    <FileDownloadIcon
                      style={{ fill: '#3355D1',cursor: 'pointer' }}
                      aria-label={"download video"}
                      onClick={() => download(row.media_id, row.rightsholder)} />
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center'}}>
                      <PencilIcon onClick={() => onEditRequested(row)}/>
                  </Grid>
                </Grid>}
              </TableCellBody>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
