import { Button, Grid, LinearProgress } from '@mui/material';
import * as React from 'react';
import { PublicationSelector } from './PublicationSelector';
import { useContext, useEffect, useState } from 'react';
import { Heading } from './common/Heading';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Moment from 'react-moment';
import { TableCellHead, TableCellBody, TableCellBodyLight } from './common/Table';
import { BoldTypography, BoldTypographyLarge } from './common/TextLabel';
import { JWPlayerPreview } from './modal/JWPlayerPreview';
import { VideoThumbnail } from './common/VideoThumbnail';
import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { EditBrandedContentDialog } from './modal/EditBrandedContentDialog';
import { Countries } from '../constant/countries';
import PromotedContentTabs from './promoted-content/PromotedContentTabs';
import PaginationTable from './Pagination';
import { OrganisationContext } from '../App';

const TableRoot = styled(Grid)`
    margin-top: 80px;
    flex-basis: 100%;
`;

const fetchBrandedContent = async (publication, params) => {
  return axios
    .get(
      `${getApiBaseUrl()}/video-player/${publication}/promoted-content`,
      {
        params: params
      }
    ).then(({ data }) => data).catch((err) => {
      console.error(err);
      return null;
    });
};

const fetchCampaigns = async (publication) => {
  return axios
    .get(
      `${getApiBaseUrl()}/video-player/${publication}/promoted-content-campaign`,
    ).then(({ data }) => data).catch((err) => {
      console.error(err);
      return null;
    });
};

const PacingCurrentLabel = styled('span')`
    color: #4caf50;
`;

const CampaignHeading = styled(Heading)`
    margin-bottom: 20px;
`;

export const TableCellCountry = styled(TableCellBody)`
    width: 30%;
`;

const Campaigns = ({ list, previewVideoUrl, setEditContent }) => {
  const getStatusText = ({ enabled, status, startTimestamp }) => {
    if (status && enabled) {
      return 'Active';
    } else if (status && !enabled) {
      return 'Paused';
    } else if (!status && startTimestamp > (new Date().getTime() / 1000)) {
      return 'Scheduled';
    } else {
      return 'Completed';
    }
  };
  return (<Table size="small">
    <TableHead>
      <TableRow>
        <TableCellHead width={"5%"}>Status</TableCellHead>
        <TableCellHead>Promoted content</TableCellHead>
        <TableCellHead>Media id</TableCellHead>
        <TableCellHead>Campaign Name</TableCellHead>
        <TableCellHead>Geo country</TableCellHead>
        <TableCellHead>Pacing</TableCellHead>
        <TableCellHead>Start date</TableCellHead>
        <TableCellHead>End date</TableCellHead>
        <TableCellHead>Tools</TableCellHead>
      </TableRow>
    </TableHead>
    <TableBody>
      {list.map((line) => (<TableRow key={line.sk}>
        <TableCellBody><BoldTypography>{getStatusText(line)}</BoldTypography></TableCellBody>
        <TableCellBodyLight>
          <Grid container minWidth={'400px'} flexDirection={'row'} alignItems={'center'} spacing={2}>
            <Grid item xs={3}
                  onClick={() => previewVideoUrl(`https://cdn.jwplayer.com/v2/media/${line.media.mediaid}`)}>
              <VideoThumbnail imageUrl={line.media.images[0].src}/>
            </Grid>
            <Grid item xs={9} data-campaign-id={line.id}>
              {line.media.title}
            </Grid>
          </Grid>
        </TableCellBodyLight>
        <TableCellBodyLight>{line.videoId}</TableCellBodyLight>
        <TableCellBodyLight>{line.campaignName}</TableCellBodyLight>
        <TableCellCountry>
          {line.geoCountryCode.length > 0 &&
            <p>
              <BoldTypographyLarge>Include: {line.geoCountryCode.length === Countries.length ? 'All countries' : line.geoCountryCode.join(', ')}</BoldTypographyLarge>
            </p>}
          {line.geoCountryCodeExclusion.length > 0 &&
            <BoldTypographyLarge>Exclude: {line.geoCountryCodeExclusion.length === Countries.length ? 'All' +
              ' countries' : line.geoCountryCodeExclusion.join(', ')}</BoldTypographyLarge>}
        </TableCellCountry>
        <TableCellBody><BoldTypographyLarge sx={{ whiteSpace: 'nowrap'}}><PacingCurrentLabel
          title={`daily target: ${line.dailyImpressionTarget}`}>{line.eventVideoStartPlaying}</PacingCurrentLabel> / {line.totalImpressionTarget}
        </BoldTypographyLarge></TableCellBody>
        <TableCellBody><BoldTypographyLarge><Moment
          format="DD/MM/YYYY">{line.startTimestamp * 1000}</Moment></BoldTypographyLarge></TableCellBody>
        <TableCellBody><BoldTypographyLarge><Moment
          format="DD/MM/YYYY">{line.endTimestamp * 1000}</Moment></BoldTypographyLarge></TableCellBody>
        <TableCellBody>
          <SvgIcon style={{ cursor: 'pointer' }} onClick={() => setEditContent(line)}>
            <PencilIcon/>
          </SvgIcon>
        </TableCellBody>
      </TableRow>))}
    </TableBody>
  </Table>);
};

export default function PromotedContentList() {
  const { selectedOrganisation } = useContext(OrganisationContext);
  const [lineItem, setLineItem] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [editContent, setEditContent] = React.useState<any>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(20);
  const [busy, setBusy] = useState(false);

  const nowTimestamp = Math.floor(new Date().getTime() / 1000);
  const initialCampaign = {
    enabled: true,
    videoId: '',
    pathInclude: '*',
    pathExclude: '',
    totalImpressionTarget: 0,
    geoCountryCode: [],
    geoCountryCodeExclusion: [],
    startTimestamp: nowTimestamp,
    endTimestamp: nowTimestamp,
    priority: 0,
    dailyImpressionTarget: 0,
    orgSlug: selectedOrganisation,
  };

  const campaignListNormalizer = ({ items, total}) => {
    if (items === null) return {
      total: 0,
      items: []
    };

    return {
      total,
      items: items.map(data => Object.fromEntries(Object.entries(data).map(([key, value]) => {
          switch (key) {
            case 'pathInclude':
            case 'pathExclude': {
              if (Array.isArray(value)) {
                return [key, value.join(', ')];
              }
              return [key, value];
            }
            default:
              return [key, value];
          }
        }))
      )
    };
  };

  const saveBrandedContent = (data) => {
    const action = data.id ? 'patch' : 'post';
    const cleanedData = Object.fromEntries(Object.entries(data).map(([key, value]) => {
      switch (key) {
        case 'pathInclude':
        case 'pathExclude':
          return [key, (value as String).split(',').map(x => x.trim())];
        default:
          return [key, value];
      }
    }));
    axios[action](
      `${getApiBaseUrl()}/promoted-content/${selectedOrganisation}`,
      cleanedData,
    ).then(() => {
      setEditContent(false);
      fetchBrandedContent(selectedOrganisation, { page: currentPage, limit: limit })
        .then(campaignListNormalizer)
        .then(({ items, total }) => {
          setLineItem(items);
          setTotalItems(total)
        });
      fetchCampaigns(selectedOrganisation).then(setCampaigns);
    }).catch((ex) => {
      const { message } = ex.response?.data;
      if (Array.isArray(message) && message.length > 0) {
        alert(message.join('/n'));
      }
    });
  };

  useEffect(() => {
    if (!videoUrl) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    setBusy(true);
    fetchBrandedContent(selectedOrganisation, { page: currentPage, limit: limit })
      .then(campaignListNormalizer)
       .then(({ items, total }) => {
          setLineItem(items);
          setTotalItems(total);
          setBusy(false);
        }).catch(() => setBusy(false));
    fetchCampaigns(selectedOrganisation).then(setCampaigns);
  }, [selectedOrganisation, currentPage, limit]);

  return (
    <React.Fragment>
      <JWPlayerPreview open={open} handleClose={() => setOpen(false)} videoUrl={videoUrl}/>
      <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
        <Grid container alignItems="center" justifyContent={'flex-end'}>
          <Grid item>
            <PublicationSelector />
          </Grid>
        </Grid>
        <PromotedContentTabs/>
        <TableRoot>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <CampaignHeading>Campaigns</CampaignHeading>
            </Grid>
            <Grid item>
              <Button variant={'contained'} onClick={() => setEditContent((initialCampaign))}>Add
                new</Button>
            </Grid>
          </Grid>
          {lineItem && <Campaigns setEditContent={setEditContent} previewVideoUrl={setVideoUrl}
                                  list={lineItem}/>}
        </TableRoot>
        <Grid container sx={{ position: 'relative'}}>
          {busy && <LinearProgress color="success" sx={{ width: '100%', position: 'absolute', top: 0, left: 0 }} />}
          <Grid item xs={12}>
            <PaginationTable
              defaultSize={20}
              busy={false}
              startPage={currentPage - 1}
              count={totalItems}
              onChange={page => {
                setCurrentPage(page + 1);
              }}
              updateRowPerPage={totalPerPage => {
                setLimit(totalPerPage);
                setCurrentPage(1);
              }}
            />
          </Grid>
        </Grid>

      </Grid>
      {editContent && <EditBrandedContentDialog
        campaigns={campaigns}
        data={editContent}
        handleClose={() => setEditContent(null)}
        open={Boolean(editContent)}
        onSave={saveBrandedContent}
      />}
    </React.Fragment>
  );
}
