import { VideoPlayerProps } from '../../types/videos';
import { Grid, Typography } from '@mui/material';
import Moment from 'react-moment';
import * as React from 'react';
import { MuxVideoPlayer } from './MuxVideoPlayer';

const Video = ({ video, header }: { video: VideoPlayerProps, header: React.ReactElement }) => {
  return (
    <>
      <Grid key={video.media_id} sx={{
        display: 'flex',
        maxWidth: video.format === 'vertical' ? '50%' : '95%',
        justifySelf: 'center',
        mt: 1,
        mb: 2
      }} container spacing={1}>
        <Grid item xs={12}>
          <Grid container alignItems={'center'}>
            {header}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {video.video_type === 'pixels' && <MuxVideoPlayer video={video} />}
          {video.video_type !== 'pixels' && <Typography fontSize={'12px'}>No support to preview this type of player ({video.video_type})</Typography>}
        </Grid>
        <Grid item xs={9}>
          <Typography fontSize={'14px'}>{video.title}</Typography>
        </Grid>
        <Grid item xs={3}>
          { video.publish_date && <Typography textAlign={'right'} fontSize={'14px'}><Moment format="DD/MM/YY">{video.publish_date}</Moment></Typography>}
        </Grid>
      </Grid>
    </>
  );
};

export default Video;