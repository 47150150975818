import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

type SummaryProps = {
  summary: string
}
export const PanelVideoSummary = ({ summary }: SummaryProps) => {
  return <Grid container spacing={1}>
    <Grid item xs={12} justifyContent={'center'}>
      <Box sx={{
        minHeight: '400px',
        p: 4
      }}>{summary || 'Summary not available.'}</Box>
    </Grid>
  </Grid>;
};