import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableBody from '@mui/material/TableBody';
import { BoldTypographyLarge } from '../common/TextLabel';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import { capitalise } from '../../utils/common';
import { PlaylistTrendingItem } from '../types/playlist';
import { ReactComponent as GainingIcon } from '../../icons/gaining-article.svg';
import { Link } from '../common/Link';
import { getOrgLogo } from '../PublicationSelector';

export const PlaylistTrendingArticleList = ({ articles, setVideoPreview }: { articles: PlaylistTrendingItem[], setVideoPreview: (x: any) => void}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead sx={{ whiteSpace: 'nowrap' }}>PAGE VIEW</TableCellHead>
          <TableCellHead></TableCellHead>
          <TableCellHead>THUMBNAIL</TableCellHead>
          <TableCellHead sx={{minWidth: '280px'}}>TITLE</TableCellHead>
          <TableCellHead>PUBLISH DATE</TableCellHead>
          <TableCellHead>SCORE</TableCellHead>
          <TableCellHead>VIDEO</TableCellHead>
          <TableCellHead>VIDEO TITLE</TableCellHead>
          <TableCellHead>VIDEO RIGHTSHOLDER</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {articles.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={9} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>This playlist contains no trending article yet</div>
            </TableCellBody>
          </TableRow>
        )}
        {articles.length > 0 &&
          articles.map(row => (
            <TableRow
              sx={{
                height: '60px',
                backgroundColor: row.horizontal_matched ? '#f2feff' : (row.has_video_matched ? 'lightyellow' : 'transparent')
              }}
              key={row.article_id}
            >
              <TableCellBody align="center">{row.event_count.toLocaleString()}</TableCellBody>
              <TableCellBody align="center">{row.is_trending?  <GainingIcon style={{ verticalAlign: 'bottom' }} /> : ''}</TableCellBody>
              <TableCellBody sx={{ width: '104px' }}>
                <div
                  style={{
                    backgroundImage: `url(${row.article_thumbnail || '/bg-grey.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: '4 / 3',
                    position: 'relative'
                  }}
                >{getOrgLogo(row.organisation_origin) === 'unknown-logo' ? '' : <img style={{
                  position: 'absolute',
                  bottom: '3px',
                  left: '3px',
                }} width='20px' alt={row.organisation_origin} src={`/logo/${row.organisation_origin}.png`} />}</div>
              </TableCellBody>
              <TableCellBody>
                {row.web_url ? <Link href={row.web_url} target={'_blank'}>{row.headline_translated}</Link> : row.headline_translated}
              </TableCellBody>
              <TableCellBody>
                {row.article_publish_date && <Moment format="Do MMM">{row.article_publish_date * 1000 }</Moment>}
              </TableCellBody>
              <TableCellBody>
              {row.matched_score_percentage ? `${row.matched_score_percentage}%` : ''}
              </TableCellBody>
              <TableCellBody>
                { !row.horizontal_matched && row.video_thumbnail && <div
                   onClick={() => setVideoPreview(row)}
                  style={{
                    backgroundImage: `url(${row.video_thumbnail || '/bg-grey.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: row.format === 'vertical' ? '9 / 16' : '4 / 3',
                    position: 'relative'
                  }}
                >
                   { row.opus_clip_id && <img src="/logo/pixels.png" style={{
                    width: '12px',
                    borderRadius: '4px',
                    position: 'absolute',
                    top: '6px',
                    right: '6px',
                  }} alt=""/> }
                </div>}
                 { row.horizontal_matched && <div
                   onClick={() => setVideoPreview({
                     ...row.horizontal_matched,
                     media_id: row.horizontal_matched.video_media_id,
                     title: row.horizontal_matched.video_title,
                   })}
                  style={{
                    backgroundImage: `url(${row.horizontal_matched.video_thumbnail || '/bg-grey.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: '4 / 3',
                    cursor: 'pointer'
                  }}
                ></div>}
              </TableCellBody>
              <TableCellBody>
                { !row.horizontal_matched && row.video_title}
                { row.horizontal_matched && row.horizontal_matched.video_title }
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '150px' }}>
                <BoldTypographyLarge>{capitalise(row.video_rightsholder)}</BoldTypographyLarge>
              </TableCellBody>

            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
