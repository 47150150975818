import * as React from 'react';
import { decode } from 'he';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { TableRowPrimary } from './SmartLibrary';
import { TableCellBody, TableCellHead } from './common/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { Link } from './common/Link';
import PaginationTable from './Pagination';
import DemoPublisherTabs from './admin/DemoPublisherTabs';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';

const ActionRoot = styled(Grid)`
  margin-bottom: 20px;
`;

const ErrorBox = styled(Grid)`
  padding: 16px;
  background-color: #eee;
`;

const CodePre = styled('pre')`
  white-space: pre-wrap;
`;

const Root = styled('div')`
  position: relative;

  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

type Article = {
  articleid: number;
  headline: string;
  url: string;
  thumbnail: string;
  video_id: string;
  is_video: boolean;
  is_visible_in_dashboard: boolean;
  video_score: number;
};

const ArticleRow = ({ data: article }) => {
  return (
    <TableRowPrimary sx={{ height: '60px', position: 'relative' }}>
      <TableCellBody>
        {article.imagefield && <img alt={article.headline_} style={{ maxWidth: 80 }} src={article.imagefield} />}
      </TableCellBody>
      <TableCellBody>
        <Link className="hover-underline" target="_blank" variant="body1" href={article.weburl}>
          {decode(article.headline)}
        </Link>
      </TableCellBody>
      <TableCellBody>{article.articletype}</TableCellBody>
      <TableCellBody>{article.section}</TableCellBody>
      <TableCellBody>{new Date(article.publishdate * 1000).toLocaleString()}</TableCellBody>
    </TableRowPrimary>
  );
};

export default function PublishersDemoArticles() {
  const { orgSlug } = useParams();
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);
  const [articles, setArticles] = useState<Article[]>([]);
  // const [progressText, setProgressText] = useState('');
  const [articleTypeRatioText, setArticleTypeRatioText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [orgName, setOrgName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(20);
  const [busy, setBusy] = useState(false);

  const handleUpdateArticles = () => {
    axios.patch(`${getApiBaseUrl()}/organisation/demo/${orgSlug}/update-counter`, {}).then(getArticles);
  };

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to remove the existing articles?')) {
      return;
    }
    axios.delete(`${getApiBaseUrl()}/article-demo/${orgSlug}`).then(getArticles);
  };

  const resetErrorLog = () => {
    axios.patch(`${getApiBaseUrl()}/article-demo/reset-log/${orgSlug}`);
  };

  const getArticles = () => {
    setBusy(true);
    axios
      .get(`${getApiBaseUrl()}/article-demo/${orgSlug}/articles`, {
        params: {
          page: currentPage,
          limit: limit,
        },
      })
      .then(({ data }) => {
        setArticles(data.items);
        setTotalItems(data.meta.totalItems);
        setCurrentPage(data.meta.currentPage);
        setBusy(false);
        // TBC
        // if (data.crawl_current_article_count < data.crawl_article_total) {
        //   setProgressText(`Currently importing ${data.crawl_current_article_count} out of ${data.crawl_article_total} articles`);
        // } else {
        //   setProgressText('Import completed');
        // }
        setErrorMessage(data.crawl_error_log);
      })
      .catch(ex => {
        setBusy(false);
        if (ex.message) {
          alert(ex.message);
        }
        console.error(ex);
      });
  };

  useEffect(() => {
    if (orgSlug) {
      getArticles();
      axios
        .get(`${getApiBaseUrl()}/organisation/demo/${orgSlug}`)
        .then(({ data }) => {
          setOrgName(data.name);
        })
        .catch(ex => {
          if (ex.message) {
            alert(ex.message);
          }
          console.error(ex);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug, currentPage, limit]);

  useEffect(() => {
    axios
      .get(`${getApiBaseUrl()}/article-demo/${orgSlug}/article-split`)
      .then(({ data }: { data: Record<string, number> }) => {
        const total = Object.values(data).reduce((a: number, n: number) => n + a, 0);
        const text = Object.entries(data)
          .map(([a, b]) => `${a} = ${((b / total) * 100).toFixed(2)}%`)
          .join(', ');
        if (text) {
          setArticleTypeRatioText(`Article type split: ${text}`);
        }
      });
  }, [orgSlug]);

  return (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoImageSwapDialog
          open={Boolean(articleVideoPreview)}
          handleClose={() => setArticleVideoPreview(false)}
          article={articleVideoPreview}
          onRemoveVideo={() => {}}
          onAddVideo={() => {}}
          type={'review'}
        />
      )}
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px', alignItems: 'stretch' }}>
          <Grid container alignItems="stretch" justifyContent={'space-between'}>
            <Grid item>{orgName && <Heading>{orgName} demo</Heading>}</Grid>
            <Grid item>
              {/*{progressText && <p>{progressText}</p>}*/}
              {articleTypeRatioText && <p>{articleTypeRatioText}</p>}
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <ActionRoot container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Button href={'/publishers'} variant={'outlined'}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={resetErrorLog} variant={'outlined'}>
              Reset error log
            </Button>
          </Grid>
        </ActionRoot>
        <DemoPublisherTabs />
        {busy && <LinearProgress color="success" />}
        {errorMessage && (
          <ErrorBox>
            <Typography color={'error'}>Error message found on the log.</Typography>
            <CodePre>{errorMessage}</CodePre>
          </ErrorBox>
        )}
        {articles.length === 0 && !busy && <Typography align={'center'}>No article found.</Typography>}
        {articles.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRowPrimary>
                <TableCellHead>Thumbnail</TableCellHead>
                <TableCellHead>Headline</TableCellHead>
                <TableCellHead>Article Type</TableCellHead>
                <TableCellHead>Section</TableCellHead>
                <TableCellHead>Publish date</TableCellHead>
              </TableRowPrimary>
            </TableHead>
            <TableBody>
              {articles.map(article => (
                <ArticleRow key={article.articleid} data={article} />
              ))}
            </TableBody>
          </Table>
        )}
        {busy && <LinearProgress color="success" />}
        {articles.length > 0 && (
          <PaginationTable
            defaultSize={20}
            busy={false}
            startPage={currentPage - 1}
            count={totalItems}
            onChange={page => {
              setCurrentPage(page + 1);
            }}
            updateRowPerPage={totalPerPage => {
              setLimit(totalPerPage);
            }}
          />
        )}
        {articles.length > 0 && (
          <ActionRoot container spacing={2} justifyContent={'space-between'}>
            <Grid item>
              <Button onClick={handleUpdateArticles} variant={'outlined'}>
                Run again
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleDelete} color={'error'} variant={'contained'}>
                Remove all and run again
              </Button>
            </Grid>
          </ActionRoot>
        )}
      </Root>
    </React.Fragment>
  );
}
