import * as React from 'react';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../pixels-ai-logo-white.svg';
import { ReactComponent as PIcon } from '../icons/short_logo_white.svg';
import { ReactComponent as AccountIcon } from '../icons/account.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DrawerListItemText } from './common/TextLabel';
import Menu from './menus/Menu';
import MainSections from './sections/MainSections';
import DemoSections from './sections/DemoSections';
import AdminSections from './sections/AdminSections';
import MenuItem from './menus/MenuItem';
import { OrganisationContext } from '../App';
import { DASHBOARD_ACCESS } from './types/constant';
import PublisherViewOfRightsholderMenu from './menus/PublisherViewOfRightsholderMenu';
import RightsholderViewOfPublisherMenu from './menus/RightsholderViewOfPublisherMenu';
import DemoMenu from './menus/DemoMenu';
import MenuCampaign from './menus/MenuCampaign';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    fontFamily: 'ProximaNovaSemiBold',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface DashboardProps {
  section: string;
  demoPage?: boolean;
  adminPage?: boolean;
}

const Dashboard = ({ section }: DashboardProps) => {
  const { currentAccess } = useContext(OrganisationContext);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  let navigate = useNavigate();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  const logOut = () => {
    if (window.localStorage) {
      localStorage.setItem('token', '');
      localStorage.setItem('pubAccess', '');
      localStorage.setItem('selectedOrganisation', '');
    }
    navigate('/', { replace: true });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet title={'Dashboard'}></Helmet>
      <CssBaseline />
      {!open && (
        <ChevronRightIcon
          className={open ? 'show' : 'hide'}
          style={{
            color: 'white',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1300,
            backgroundColor: '#3355D1',
            borderRadius: '50%',
            border: '1px solid rgb(255, 255, 255, 0.4)',
            top: 18,
            boxSizing: 'content-box',
            left: 59,
            padding: 2,
            transition: 'opacity 0.3s',
            opacity: 1,
          }}
          onClick={toggleDrawer}
        />
      )}

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: '#3355D1',
            color: 'white',
          },
        }}
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: [1],
          }}
        >
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Link
              style={{
                paddingLeft: 16,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
              href="/"
            >
              {open && <img onClick={toggleDrawer} src={logo} alt="Pixels" />}
              {!open && <PIcon />}
            </Link>
          </header>
          {open && (
            <ChevronLeftIcon
              style={{
                color: 'white',
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
              }}
              onClick={toggleDrawer}
            />
          )}
        </Toolbar>
        <Divider />
        <List>
          {currentAccess === DASHBOARD_ACCESS.NON_RIGHTSHOLDER && <Menu open={open} />}
          {currentAccess === DASHBOARD_ACCESS.VIDEO_PUBLISHER && <RightsholderViewOfPublisherMenu open={open} />}
          {currentAccess === DASHBOARD_ACCESS.CAMPAIGN_ORGANISATION && <MenuCampaign open={open} />}
          {currentAccess === DASHBOARD_ACCESS.VIDEO_RIGHTSHOLDER && <PublisherViewOfRightsholderMenu open={open} />}
          {currentAccess === DASHBOARD_ACCESS.DEMO && <DemoMenu open={open} isAdmin={isAdmin} />}
        </List>
        <List style={{ position: 'fixed', bottom: 0, width: open ? drawerWidth : 72 }}>
          {/* removing as not currently used... */}
          {/* {!isAdmin && (
            <ListItem button>
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <DrawerListItemText disableTypography={true} primary="Account" />
            </ListItem>
          )} */}
          {isAdmin && (
            <>
              <MenuItem open={open} path="/opus-jobs" label="Edit Jobs">
                <AccountIcon />
              </MenuItem>
              <MenuItem open={open} path="/publishers" label="Publishers">
                <AccountIcon />
              </MenuItem>
              <MenuItem open={open} path="/users" label="Users">
                <AccountIcon />
              </MenuItem>
            </>
          )}
          <ListItemButton onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <DrawerListItemText disableTypography={true} primary="Log Out" />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {currentAccess !== DASHBOARD_ACCESS.DEMO && (
            <MainSections section={section} />
          )}
          {currentAccess === DASHBOARD_ACCESS.DEMO && (
            <DemoSections
              section={section}
            />
          )}
          {isAdmin && (
            <AdminSections
              section={section}
              isDemo={currentAccess === DASHBOARD_ACCESS.DEMO}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
