import * as React from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import DemoPublisherTabs from './admin/DemoPublisherTabs';
import { useDispatch, useSelector } from 'react-redux';
import { apiArticleProvider } from '../store/imageSwapSlice';
import { ImageSwapArticleTable } from './ImageSwapArticleTable';
import PaginationTable from './Pagination';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';

const ActionRoot = styled(Grid)`
  margin-bottom: 20px;
`;

const Root = styled('div')`
  position: relative;

  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function PublishersDemoFMResult() {
  const dispatch = useDispatch();
  const { orgSlug } = useParams();
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const downloadCSV = () => {
    axios
      .get(`${getApiBaseUrl()}/${orgSlug}/article-match/download-csv`, {
        headers: {
          'Content-Type': 'text/csv',
        },
        params: {
          publisher: orgSlug,
          limit: null,
          page: 1,
          filterByDate: articlesFilter,
          orderBy: orderBy,
          orderDirection: orderDirection,
          isGroupByKeyword,
          keyword_id: filteredTopic.join(','),
          type: 'all-matches',
        },
      })
      .then(response => {
        const blob = new Blob([response.data]);
        const filename = response.headers['x-filename'];
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const { articlesFilter, busy, isGroupByKeyword, articles, pagination, filteredTopic, formatFilter } = useSelector(
    (state: any) => state.imageSwap
  );

  const [orderDirection, setOrderDirection] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('traffic');
  // const [progressText, setProgressText] = useState('');
  const [orgName, setOrgName] = useState('');
  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);

  useEffect(() => {
    if (orgSlug) {
      axios
        .get(`${getApiBaseUrl()}/organisation/demo/${orgSlug}`)
        .then(({ data }) => {
          setOrgName(data.name);
        })
        .catch(ex => {
          if (ex.message) {
            alert(ex.message);
          }
          console.error(ex);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug]);

  const sorting = type => () => {
    if (orderBy !== type) {
      setOrderDirection('desc');
    }
    if (orderBy === type) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(type);
  };

  useEffect(() => {
    dispatch<any>(
      apiArticleProvider({
        publisher: orgSlug,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByDate: articlesFilter,
        orderBy: orderBy,
        orderDirection: orderDirection,
        isGroupByKeyword,
        keyword_id: filteredTopic.join(','),
        format: formatFilter,
        type: 'all-matches',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orgSlug,
    pagination.itemsPerPage,
    pagination.currentPage,
    articlesFilter,
    orderDirection,
    orderBy,
    isGroupByKeyword,
    formatFilter,
  ]);

  return (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoImageSwapDialog
          open={Boolean(articleVideoPreview)}
          handleClose={() => setArticleVideoPreview(false)}
          article={articleVideoPreview}
          onRemoveVideo={null}
          onAddVideo={null}
          type={'review'}
        />
      )}
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px', alignItems: 'stretch' }}>
          <Grid container alignItems="stretch" justifyContent={'space-between'}>
            <Grid item>{orgName && <Heading>{orgName} demo</Heading>}</Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <ActionRoot container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Button href={'/publishers'} variant={'outlined'}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={downloadCSV}>Download csv</Button>
          </Grid>
        </ActionRoot>
        <DemoPublisherTabs />
        {busy && <LinearProgress color="success" />}
        <ImageSwapArticleTable
          articles={articles}
          orderBy={orderBy}
          orderDirection={orderDirection}
          onSortByTraffic={sorting('traffic')}
          onSortByDate={sorting('date')}
          onSortByScore={sorting('score')}
          onSetArticleForPreview={setArticleVideoPreview}
          articleDemo={false}
          selectedPublisher={orgSlug}
        />
        <PaginationTable
          defaultSize={20}
          busy={busy}
          startPage={pagination.currentPage - 1}
          count={pagination.totalItems}
          onChange={page =>
            dispatch({
              type: 'SET_PAGINATION',
              payload: {
                currentPage: page + 1,
              },
            })
          }
          updateRowPerPage={totalPerPage => {
            dispatch({
              type: 'SET_PAGINATION',
              payload: {
                currentPage: 1,
                itemsPerPage: totalPerPage,
              },
            });
          }}
        />
      </Root>
    </React.Fragment>
  );
}
