import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { VideoLibItem } from '../../types/videos';
import Box from '@mui/material/Box';
import VideoBox from './VideoBox';
import { apiVideoBestVariantProvider } from '../../../store/videoLibrarySlice';
import { useDispatch } from 'react-redux';
import { useContext, useState } from 'react';
import { OrganisationContext } from '../../../App';

type VideoVariationsProps = {
  variations: VideoLibItem[],
  parentMediaId: string;
  currentSelectedVideo: string,
  showSelector: boolean,
  updateVideoCb: () => void,
}

export const PanelVideoVariation = ({ variations, currentSelectedVideo, showSelector, parentMediaId, updateVideoCb }: VideoVariationsProps) => {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
  const [updateClipInProgress, setUpdateClipInProgress] = useState<string>();

  const updateBestClip = async ({ parentMediaId, selectedMediaId }) => {
    await dispatch(apiVideoBestVariantProvider({
      publisher: selectedOrganisation,
      parentMediaId, selectedMediaId
    }));
    updateVideoCb();
    setUpdateClipInProgress(selectedMediaId)
  }

  return (
    <Grid container spacing={1}>
      {variations.length === 0 && <Box></Box>}
      {variations.length > 0 && variations.map(({
        media_id,
        rightsholder,
        video_type,
        format,
        publish_date,
        title,
        opus_score
      }) => <Grid key={media_id} item xs={4}>
        <Box sx={{
          minHeight: '500px'
        }}>
          <VideoBox header={opus_score ? <Typography>Score: {opus_score}%</Typography> :
            <Typography>-</Typography>} video={{
            media_id,
            rightsholder,
            video_type,
            format,
            title,
            publish_date: new Date(publish_date),
          }}/>
        </Box>
        { showSelector && <Box sx={{
          textAlign: 'center'
        }}>{ currentSelectedVideo !== media_id && <Button
          disabled={updateClipInProgress === media_id}
          variant={'contained'} onClick={() => {
            setUpdateClipInProgress(media_id);
            updateBestClip({
              parentMediaId, selectedMediaId: media_id
            })
        }}>{updateClipInProgress === media_id ? 'Updating..' : 'Choose'} </Button>}
          { currentSelectedVideo === media_id && <Button disabled variant={'outlined'}>Current</Button>}
        </Box>}
      </Grid>)}
    </Grid>
  );
};


