import * as React from 'react';
import {
  Grid, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { OpusJobRequest } from './types/videos';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function OpusJobs() {
  const [jobs, setJobs] = useState<OpusJobRequest[]>([]);
  const [inProgressId, setInProgressId] = useState<string>();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const fetchList = () => {
    axios.get(
      `${getApiBaseUrl()}/video-clip/open-edit-video`,
    ).then(({ data: list }) => {
      if (list) {
        setJobs(list);
      }
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  }

  useEffect(fetchList, []);

  const reimport = (data: OpusJobRequest) => {
    setInProgressId(data.media_id);
    axios.post(
      `${getApiBaseUrl()}/video-clip-converter/reimport`, { mediaId: data.media_id }
    ).then(({ data: list }) => {
      setInProgressId(undefined);
      fetchList()
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });

  }

  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>Edit Jobs</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <TableContainer component={Paper} sx={{ maxWidth: 1200 }} >

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Requester</TableCell>
                <TableCell>Instruction</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.user_email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <pre>{row.instruction}</pre>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.created_at}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.completed_at}
                  </TableCell>
                  <TableCell align="right">
                    { inProgressId !== row.media_id && !row.completed_at && <SvgIcon onClick={() => reimport(row)}>
                      <CloudSyncIcon />
                    </SvgIcon>}
                    { inProgressId !== row.media_id && row.completed_at && <Typography>-</Typography>}
                    { inProgressId === row.media_id && <Typography>Sending request..</Typography>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    </React.Fragment>
  );
};
