import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { VideoLibItem } from '../components/types/videos';

const fetchVideoLibrarySuccess = payload => ({
  type: 'FETCH_VIDEO_LIBRARY_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_VIDEO_LIBRARY_BUSY',
  payload,
});

export const apiVideoLibraryProvider =
  ({ publisher, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/${publisher}/video-library`, {
        params,
      });
      dispatch(fetchVideoLibrarySuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiVideoVariantProvider =
  ({ publisher, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/${publisher}/video-library/variations`, {
        params,
      });
      dispatch({
        type: 'FETCH_VIDEO_VARIATIONS_SUCCESS',
        payload: data
      });
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export const apiVideoBestVariantProvider =
  ({ publisher, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      await axios.post(`${getApiBaseUrl()}/video-clip/${publisher}/select-best-clip`, params);
      dispatch({
        type: 'UPDATE_VIDEO_VARIATIONS_SUCCESS',
      });
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface VideoLibState {
  videos: VideoLibItem[];
  videoLibraryFilter: string;
  searchText: string;
  paginationPage: number;
  busy: boolean;
  paginationPerPage: number;
  videoVariation: [];
  pagination: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

const initialState: VideoLibState = {
  videos: [],
  videoVariation: [],
  videoLibraryFilter: 'vertical',
  searchText: '',
  paginationPage: 0,
  paginationPerPage: 20,
  busy: false,
  pagination: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGINATION':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case 'SET_FILTER_VIDEO_LIBRARY':
      return {
        ...state,
        videoLibraryFilter: action.payload,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };
    case 'SET_VIDEO_LIBRARY_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'RESET_VIDEO_VARIATION':
       return {
        ...state,
        videoVariation: initialState.videoVariation,
      };
    case 'FETCH_VIDEO_VARIATIONS_SUCCESS':
      return {
        ...state,
        videoVariation: action.payload,
      };
    case 'FETCH_VIDEO_LIBRARY_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    case 'FETCH_VIDEO_LIBRARY_SUCCESS':
      let { items: videos, meta: pagination } = action.payload;
      return {
        ...state,
        videos,
        pagination,
      };
    default:
      return state;
  }
};

export default reducer;
