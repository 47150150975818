import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { apiPixelsSearchProvider } from '../store/pixelsSearchSlice';
import { PixelsSearchVideoTable } from './PixelsSearchVideoTable';
import ToolBarPixelsSearch from './ToolBarPixelsSearch';
import { OrganisationContext } from '../App';
import { VideoBasicPreview } from './modal/VideoBasicPreview';

export default function PixelsSearch() {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const { searchText, pixelsSearchFilter, videos, busy } = useSelector((state: any) => state.pixelsSearch);

  const [videoPreview, setVideoPreview] = useState<any>(false);
  const [searchInputText, setSearchInputText] = useState({
    searchText: '',
  });
  const handleClose = () => setVideoPreview(false);

  useEffect(() => {
    if (!selectedOrganisation || !searchText) return;

    dispatch<any>(
      apiPixelsSearchProvider({
        publisher: selectedOrganisation,
        filterByFormat: pixelsSearchFilter,
        searchText,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation, searchText, pixelsSearchFilter]);

  return (
    <>
      <ToolBarPixelsSearch setSearchInputText={setSearchInputText} searchInputText={searchInputText} />

      {videos ? (
        <Fragment>
          {videoPreview && (
            <VideoBasicPreview
              open={Boolean(videoPreview)}
              handleClose={handleClose}
              video={videoPreview}
            />
          )}

          {videos && (
            <>
              <Box sx={{ position: 'relative' }}>
                {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
                <PixelsSearchVideoTable videos={videos} onSetVideoForPreview={setVideoPreview} />
              </Box>
            </>
          )}
          {busy && <LinearProgress color="success" />}
        </Fragment>
      ) : (
        <CircularProgress style={{ padding: '5px' }} />
      )}
    </>
  );
}
