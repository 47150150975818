import Login from './components/public/Login';
import Dashboard from './components/Dashboard';

import ApiClientProvider from './api/apiClientProvider';
import { createApiClient } from './api/rootClient';

import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { getApiBaseUrl } from './utils/env';
import PrivacyPolicy from './components/PrivacyPolicy';
import axios from 'axios';
import Home from './components/public/Home';
import RevenueLossCalculator from './components/public/RevenueLossCalculator';
import Contact from './components/public/Contact';
import Sustainability from './components/public/Sustainability';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Organisation } from './components/types/dashboard';
import { DASHBOARD_ACCESS } from './components/types/constant';
import { localStorageToJSON } from './utils/common';

const apiClient = createApiClient(getApiBaseUrl());

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401 && window.localStorage) {
      localStorage.setItem('token', '');
      if (!window.location.pathname.startsWith('/login')) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error.response.data.message ?? error);
  }
);

type OrganisationCxt = {
  organisations: Organisation[],
  setOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>
  selectedOrganisation: string | null,
  setSelectedOrganisation: React.Dispatch<React.SetStateAction<string | null>>,
  setCurrentAccess: React.Dispatch<React.SetStateAction<DASHBOARD_ACCESS>>,
  currentAccess: DASHBOARD_ACCESS,
  isAdmin: boolean
}

export const OrganisationContext = createContext<OrganisationCxt>({} as OrganisationCxt);

function App() {
  const [selectedOrganisation, setSelectedOrganisation] = useState<string | null>(null);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [currentAccess, setCurrentAccess] = useState<DASHBOARD_ACCESS>(DASHBOARD_ACCESS.NON_RIGHTSHOLDER);

  return (
    <ApiClientProvider value={apiClient}>
      <OrganisationContext.Provider value={{
        selectedOrganisation,
        organisations,
        setOrganisations,
        setSelectedOrganisation,
        currentAccess,
        setCurrentAccess,
        isAdmin: localStorage.getItem('is_admin') === 'true'
      }}>
        <BrowserRouter>
          <Routes>
            {/* PUBLIC WEB */}
            <Route path="/privacy-policy/" element={<PrivacyPolicy/>}/>
            <Route path="/privacy" element={<Navigate to={'/privacy-policy'}/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/instream-revenue-uplift-calculator/" element={<RevenueLossCalculator/>}/>
            <Route path="/contact/" element={<Contact/>}/>
            <Route path="/login/" element={<Login/>}/>
            <Route path="/sustainability/" element={<Sustainability/>}/>

            {/* NORMAL ROUTES */}
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="report"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/page-view"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="pv-report"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/video-swap"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="video-swap"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/video-swap/no-matches"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="video-swap-no-matches"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/video-swap/swapped"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="video-swap-swapped"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/video-swap/improve"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="video-swap-improve"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/promoted-content/1"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="promoted-content-1"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/promoted-content/2"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="promoted-content-2"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/promoted-content/3"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="promoted-content-3"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/calculator"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="calculator"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/headline-search"
              element={
                <RequireAuth>
                  <CheckRouteAccess access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER]}>
                    <Dashboard section="headline-search"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/video-library"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_RIGHTSHOLDER]}>
                    <Dashboard section="video-library"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/playlist"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER, DASHBOARD_ACCESS.CAMPAIGN_ORGANISATION]}>
                    <Dashboard section="playlist"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/playlist/properties?/:playlist"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER]}>
                    <Dashboard section="playlist-video"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/playlist/preview?/:playlist"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER]}>
                    <Dashboard section="playlist-preview"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/playlist/popular-article/:playlist"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER]}>
                    <Dashboard section="playlist-popular-article"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/playlist/campaign-delivery/:playlist"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER]}>
                    <Dashboard section="playlist-campaign-delivery"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/gap-analysis"
              element={
                <RequireAuth>
                  <CheckRouteAccess
                    access={[DASHBOARD_ACCESS.NON_RIGHTSHOLDER, DASHBOARD_ACCESS.VIDEO_PUBLISHER]}>
                    <Dashboard section="gap-analysis"/>
                  </CheckRouteAccess>
                </RequireAuth>
              }
            />

            {/* ADMIN ROUTES */}
            <Route
              path="/opus-jobs"
              element={
                <RequireAuth>
                  <Dashboard demoPage adminPage section="opus-jobs"/>
                </RequireAuth>
              }
            />
            <Route
              path="/video-swap"
              element={
                <RequireAuth>
                  <Dashboard demoPage adminPage section="video-swap"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers/add"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers-add"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers/edit/:orgSlug"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers-edit"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers/:orgSlug/articles"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers-articles"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers/:orgSlug/videos"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers-videos"/>
                </RequireAuth>
              }
            />
            <Route
              path="/publishers/:orgSlug/fast-match-result"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="publishers-fast-match-result"/>
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="users"/>
                </RequireAuth>
              }
            />
            <Route
              path="/users/add"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="user-add"/>
                </RequireAuth>
              }
            />
            <Route
              path="/users/edit/:id"
              element={
                <RequireAuth>
                  <Dashboard adminPage section="user-edit"/>
                </RequireAuth>
              }
            />

            {/* DEMO ROUTES */}
            <Route
              path="/calculator"
              element={
                <RequireAuth>
                  <Dashboard demoPage section="calculator"/>
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </OrganisationContext.Provider>
    </ApiClientProvider>
  );
}

function RequireAuth({ children }) {
  const token = window.localStorage && window.localStorage.getItem('token');
  return token ? children : <Navigate to="/login"/>;
}

function CheckRouteAccess({ children, access }: {
  access: DASHBOARD_ACCESS[],
  children: JSX.Element
}) {
  const navigate = useNavigate();
  const {
    setSelectedOrganisation,
    setOrganisations,
    organisations,
    selectedOrganisation,
    setCurrentAccess,
    currentAccess
  } = useContext(OrganisationContext);

  useEffect(() => {
    const organisations = localStorageToJSON('pubAccess');
    setOrganisations(organisations);
    const domains = organisations.map(({ domain }) => domain);
    const selectedPublication = localStorage.getItem('selectedOrganisation');
    //ensure selected domain is actually in the list of domains...
    const org = organisations.find(({ domain }) => domain === selectedPublication);
    setSelectedOrganisation(org?.domain || domains[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedOrganisation) return;
    localStorage.setItem('selectedOrganisation', selectedOrganisation);
    const org = organisations.find(({ domain }) => domain === selectedOrganisation);

    if (org?.is_demo) {
      setCurrentAccess(DASHBOARD_ACCESS.DEMO);
    } else if (org?.is_rightsholder) {
      setCurrentAccess(DASHBOARD_ACCESS.VIDEO_RIGHTSHOLDER);
    } else if (org?.is_publisher) {
      setCurrentAccess(DASHBOARD_ACCESS.VIDEO_PUBLISHER);
    } else if (org?.is_campaign) {
      setCurrentAccess(DASHBOARD_ACCESS.CAMPAIGN_ORGANISATION);
    } else {
      setCurrentAccess(DASHBOARD_ACCESS.NON_RIGHTSHOLDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  useEffect(() => {
    if (access.includes(currentAccess)) return;


    switch (currentAccess) {
      case DASHBOARD_ACCESS.VIDEO_PUBLISHER:
        navigate('/dashboard/playlist');
        break;
      case DASHBOARD_ACCESS.VIDEO_RIGHTSHOLDER:
        navigate('/dashboard/video-library');
        break;
      default:
        navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccess]);


  return children;
}

export default App;
