import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';

import { Box, Button, FormControl, GlobalStyles, Grid, TextField, Typography } from '@mui/material';
import '../../App.css';

import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import PublicLayout from './PublicLayout';
import PropTypes from 'prop-types';
import { ContentWrap, GridCenterAligned as GridCenterAlignedBase, GridContentItem } from '../common/GridLayout';
import { Headline as BaseHeadline } from '../common/PublicTypography';
import { InputLabel as BaseInputLabel } from '../common/Form';
import { shadeBgStyles } from './Home';
import { ReactComponent as ArrowIcon } from '../../icons/button-arrow-white.svg';
import { DefaultSection as MenuDefaultSection } from '../menus/Menu';
import { DefaultSection as DemoMenuDefaultSection } from '../menus/DemoMenu';

const GridMobileWithBackground = styled(Grid)`
  position: relative;

  &:before {
    ${props => props.theme.breakpoints.down('md')} {
      content: '';
      background-image: url('/bg-home.png');
      height: 100%;
      position: absolute;
      top: 0;
      width: calc(100vw + 30px);
      left: -30px;
      transform: scaleY(-1);
      background-size: 100% 88%;
      background-repeat: no-repeat;
    }
  }
`;

const Headline = styled(BaseHeadline)`
  font-size: 38px;
  line-height: 1.14;
  max-width: 280px;
  margin-top: 0;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 64px;
    max-width: none;
  }
`;

const InputText = props => (
  <TextField
    inputProps={{
      style: {
        paddingLeft: 18,
        paddingRight: 18,
        paddingTop: 12,
        paddingBottom: 12,
      },
    }}
    {...props}
  />
);

const LoginInputField = styled(InputText)`
  background: #fff;
  border-radius: 6px;
`;

const LoginShowcaseImage = styled('div')`
  width: 100%;
  aspect-ratio: 1 / 0.75;
  position: relative;
  overflow: hidden;
  ${props => props.theme.breakpoints.up('md')} {
    overflow: visible;
    flex: 1;
  }

  &:after {
    content: '';
    background-image: linear-gradient(to bottom, rgb(235, 241, 255, 0), rgb(235, 241, 255, 0.4)),
      url(/mission-control-final-2.svg);
    height: 100%;
    position: absolute;
    top: 10px;
    width: 100%;
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat no-repeat;
    z-index: 0;

    ${props => props.theme.breakpoints.up('md')} {
      background-image: url(/mission-control-final-2.svg);
      top: 20px;
      height: calc(100% + 130px);
      background-size: contain;
      width: 200%;
      background-position: top left 10px;
      background-repeat: no-repeat;
    }
  }
`;

const Form = styled('form')`
  background-color: #ebf1ee;
  padding: 30px;
  border-radius: 4px;

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: 20px;
    margin-right: 40px;
  }
`;

const ErrorLogin = styled(Typography)`
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #bf3f3f;
  padding: 2px 6px;
  border-radius: 4px;
  line-height: 1.4;
  font-size: 13px;
  color: #fff;
`;

const FormCtaRoot = styled(Box)`
  position: relative;
  padding-top: 30px;
`;

const InputLabel = styled(BaseInputLabel)`
  color: #2e434e;
`;

const GridCenterAligned = styled(GridCenterAlignedBase)`
  ${props => props.theme.breakpoints.up('md')} {
    gap: 70px;
  }
`;

function Login({ className }) {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState({ value: '' });

  const loginRequest = payload => {
    return axios.post(`${getApiBaseUrl()}/auth/login`, payload).then(response => response.data);
  };

  const handleInputChange = e => {
    setUserData(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await loginRequest({
      ...userData,
      username: userData.email,
    }).then(
      result => {
        const { access_token: token, is_admin, organisations } = result;
        localStorage.setItem('is_admin', is_admin);
        localStorage.setItem('token', token);
        localStorage.setItem('pubAccess', JSON.stringify(organisations));
        localStorage.setItem('user_email', userData.email);

        const { is_demo } = organisations[0];
        if (is_demo) {
          window.location.href = DemoMenuDefaultSection();
        } else {
          window.location.href = MenuDefaultSection();
        }
      },
      error => {
        localStorage.setItem('is_admin', '');
        localStorage.setItem('token', '');
        localStorage.setItem('pubAccess', '');
        setErrorMessage(prevState => ({ value: typeof error === 'string' ? error : 'Invalid credentials' }));
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      window.location.href = MenuDefaultSection();
    }
  }, []);

  const pageBackgroundStyles = {
    body: {
      backgroundColor: '#274bde',
      backgroundImage: 'url("/v2/pixels-bg.svg")',
      backgroundPosition: 'top left',
      backgroundRepeat: 'repeat-x',
    },
  };

  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={{ ...shadeBgStyles, ...pageBackgroundStyles }} />
      <Helmet title="Pixels AI - Log Into Your Account">
        <meta
          name="description"
          content="Welcome to Pixels AI! Log into our centralised portal where you can oversee video matches, exercise control over selections, and obtain insights for your instream video. Our intuitive and user-friendly platform is designed to streamline your editorial workflow."
        />
      </Helmet>
      <GridCenterAligned container>
        <GridContentItem item xs={12} md={6}>
          <ContentWrap className={'ctn-root'} id="ContentWrap">
            <Headline variant={'h1'}>Login</Headline>
            <Form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="name">Email</InputLabel>
                    <LoginInputField
                      id={'email'}
                      name={'email'}
                      required
                      onChange={e => handleInputChange(e)}
                      onFocus={e => handleInputChange(e)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          return handleSubmit(e);
                        }
                      }}
                      placeholder={'Email'}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="name">Password</InputLabel>
                    <LoginInputField
                      id={'password'}
                      name={'password'}
                      type={'password'}
                      required
                      onChange={e => handleInputChange(e)}
                      onFocus={e => handleInputChange(e)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          return handleSubmit(e);
                        }
                      }}
                      placeholder={'Password'}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormCtaRoot>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {errorMessage.value && <ErrorLogin>{errorMessage.value} </ErrorLogin>}
                  <Grid item xs={12}>
                    <Button
                      type={'submit'}
                      style={{
                        fontSize: 17,
                        fontWeight: 700,
                        minHeight: '50px',
                        backgroundColor: '#3355D1',
                        textTransform: 'none',
                        justifyContent: 'space-between',
                      }}
                      fullWidth
                      variant="contained"
                      startIcon={<></>} // Workaround hack with empty fragment to center text label, while custom icon is placed at the end of the button, through custom 'justifyContent: space-between' override on style prop
                      endIcon={<ArrowIcon />}
                    >
                      Log in
                    </Button>
                  </Grid>
                </Grid>
              </FormCtaRoot>
            </Form>
          </ContentWrap>
        </GridContentItem>
        <Grid
          item
          xs={12}
          md={6}
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
          id="LoginShowcaseImageWrapper"
        >
          <LoginShowcaseImage />
        </Grid>
      </GridCenterAligned>
      <GridMobileWithBackground
        sx={theme => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <LoginShowcaseImage />
      </GridMobileWithBackground>
    </PublicLayout>
  );
}

Login.propTypes = {
  className: PropTypes.string,
};

export default styled(Login)`
  .content-root {
    display: flex;
  }

  .content-inner {
    max-width: none;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > div:first-of-type {
      flex: 1;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    .ctn-root {
      padding-top: 20px;
    }
  }
`;
